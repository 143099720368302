<template>
  <div>
     <filter-bar name="subscribers" :filters="filters"  @reset="refresh"  @filtrer="filtrer" @search="search"></filter-bar>

    <div class="min-h-screen flex-1 mt-1 bg-gray-100 px-3 items-center">
      <div class="bg-white w-fullrounded-lg shadow">
        <div class="h-20 py-3 flex items-center border-gray-200 p-6">
          <div class="flex">
            <div class="text-2xl font-bold text-green-700">{{$t('subscribers')}}</div>
          </div>
          <div class="w-full flex justify-end">
            <router-link
              class="flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              to="/subscribers/new"
            >
              <i class="material-icons">add</i> {{$t('new')}}
            </router-link>
            <div @click="downloadSubscribers" class="ml-2 flex rounded focus:outline-none outline-none text-gray-100 px-3 w-1/7 py-2 bg-green-600  hover:shadow-inner hover:bg-green-700  transition-all duration-300"
              >
              <i class="material-icons">file_download</i> csv
            </div>
          </div>
        </div>
        <div>
          <base-table
            :refresh="refresh"
            :content="content"
            :headers="headers"
            name="subscribers"
            :idWharhouse="idWharhouse"
            :currentUser="currentUser"
          >
          </base-table>
        </div>

        <div class="p-1">
          <div class="flex flex-col items-center my-12">
            <div v-if="paginate.lastpage > 1" class="flex text-gray-700">
              <button
                v-if="paginate.currentpage > 1"
                @click="prev"
                :disabled="paginate.currentpage == 1"
                class="h-8 w-8 focus:outline-none mr-6 flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer"
              >
                <i class="material-icons"> keyboard_arrow_left </i>
              </button>
              <div class="flex h-8 font-medium rounded-full bg-gray-200">
                <div
                  v-if="paginate.currentpage != paginate.lastpage"
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full bg-green-600  text-white"
                >
                  {{ paginate.currentpage }}
                </div>
                <div
                  v-else
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  <span v-if="paginate.currentpage != 1"> 1 </span>
                </div>

                <div
                  v-if="paginate.currentpage >= 1 && paginate.lastpage != 1"
                  class="w-8 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  ...
                </div>

                <button
                  :class="
                    paginate.currentpage == paginate.lastpage
                      ? 'bg-green-600  text-white'
                      : ' '
                  "
                  class="w-8 md:flex justify-center items-center hover:bg-green-600  hover:text-white focus:outline-none hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-full"
                >
                  {{ paginate.lastpage }}
                </button>
              </div>
              <button
                v-if="paginate.currentpage < paginate.lastpage"
                :disabled="paginate.currentpage == paginate.lastpage"
                @click="next"
                class="h-8 w-8 ml-6 focus:outline-none flex justify-center text-green-700    items-center rounded-full bg-gray-200 hover:bg-green-600  hover:text-white cursor-pointer"
              >
                <i class="material-icons"> keyboard_arrow_right </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
//import button from '../../components/~/button.vue';
export default {
  props: {
     currentUser: { type: Object},
     idWharhouse: { type: Object},
   },
  data() {
    return {
      content: [],
      headers: ["fullName", "phone","source", "email","date","country", "isConverted"],
      IsConverted: ['Converted', 'Non Converted'],
      limit: 10,
      paginate: {
        total: 0,
        currentpage: 1,
        lastpage: 0,
        per_page: 0,
      },
      filters: {
            name: 'subscribers',
            elements: {
                search: {
                    options: {
                        icon: "search",
                        label: "search",
                        class: "",
                        hint: "search_subscriber"
                    },
                    value: null,
                    type: "search",
                    validate: {
                        trim: true,
                        required: false,
                        minlength: 0,
                    }
                },
                champs: [
                   {
                        label: "converted",
                        key: "isConverted",
                        name: "isConverted",
                        value: null,
                        values: [],
                        class: 'py-2 text-xs  capitalize px-2 outline-none block h-full w-full bg-white',
                        type: "select",
                    },
                ],
            },
      },
    };
  },
  async mounted() {
    await this.getSubscribers({ limit: this.limit });
     for (var i = 0; i < this.filters.elements.champs.length; i++) {
           this.filters.elements.champs[i].values = this.IsConverted;
      }
    
  },
  methods: {
    async getSubscribers(filters) {
     // filters['country']=await this.warhouseSelected;
      const res = await this.$server.search("subscribers", filters);
      if (res.content && res.content.results) {
        this.content = res.content.results;
        this.paginate.total = res.content.total;
        this.paginate.lastpage = res.content.last_page;
        this.paginate.per_page = res.content.per_page;
        this.paginate.currentpage = res.content.current_page;
      } else {
        this.content = [];
      }
    },
    async refresh() {
      this.getSubscribers({ limit: this.limit });
    },
     async reset() {
      await this.getSubscribers({limit: this.limit});
      },
    async search(data) {
      const filter = {
        name: data,
       // country:await this.warhouseSelected
      };
      const res = await this.$server.find("subscribers", filter);
      //console.log(res);
      if (res.content) this.content = res.content;
      else this.content = [];
    },
     async filtrer(data) {
       console.log('result ',data)
        const filters = {
            isConverted: data.isConverted,
            limit: this.limit,
    };
      await this.getSubscribers(filters);
    },
    /*********************************************************************************/
    async next() {
      const filters = {
        page: ++this.paginate.currentpage,
        limit: this.limit,
      };
      await this.getSubscribers(filters);
    },
    async prev() {
      const filters = {
        page: --this.paginate.currentpage,
        limit: this.limit,
      };
      await this.getSubscribers(filters);
    },
    async downloadSubscribers(){
      //console.log('downloading...')
      const filters = {};
      const res = await this.$server.export("subscribers", filters);
      //console.log(res);
      if(res && res.content){
        let link = window.document.createElement("a");
        link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(res.content));
        link.setAttribute("download", "subscribers.csv");
        link.click();
      }
      
    }
  },
};
</script>

<style>
</style>